'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var formPrepare = require('./../../../../app_storefront_core/cartridge/js/pages/checkout/formPrepare');
var dialog = require('./../dialog');
var layout = require('./../layout');
var progress = require('./../progress');

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.contactUsModal = $('.contact-us-modal');
};

function initializeEvents() {
    $cache.document.on('change', '#dwfrm_contactusemail_isPurchaseRelated', function(event){
        event.preventDefault();
        toggleFields();
    });

    $cache.document.on('submit', '#contactUsEmailForm', function(event) {
        event.preventDefault();

        progress.show($cache.contactUsModal);

        var $form = $(this);
        var dataArr = $form.serialize().split('&');
        var fakeDataArr = [];
        var requiredFields = $form.find('.required:input');
        requiredFields.each(function() {
            //set fake data for empty required data for form at xml level
            if (!this.value) {
                fakeDataArr.push(this.name + '=' + 'xxxxxxxxxx')
            }
        });

        var newDataArr = dataArr.filter(function(item) {
            return !item || item.indexOf('=') + 1 !== item.length;
        });

        var submitName = $form.find('button').attr('name');
        var data = [newDataArr.join('&'), fakeDataArr.join('&'), submitName + '=true'].join('&');

        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: data,
            success: function(data) {
                dialog.openWithContent({
                    html: data
                });

                progress.hide();
            }
        })
    });
};

function toggleRelatedPurchaseFields() {
    $('.f-field--typeOfPurchase').toggleClass('hidden');
    $('.f-field--orderNumber').toggleClass('hidden');
    $('.f-field--billingPostCode').toggleClass('hidden');

    var $whereIsMyOrder = $('[value="whereIsMyOrder"]');
    var $itemFaulty = $('[value="itemFaulty"]');
    var $cancelOrder = $('[value="cancelOrder"]');
    var $discussReturn = $('[value="discussReturn"]');
    var $missingItem = $('[value="missingItem"]');
    var $helpOption = $('[value="helpOption"]');

    $whereIsMyOrder.toggleClass('hidden');
    $itemFaulty.toggleClass('hidden');
    $cancelOrder.toggleClass('hidden');
    $discussReturn.toggleClass('hidden');
    $missingItem.toggleClass('hidden');
    $helpOption.toggleClass('hidden');

    if (!layout.isMobile()) {
        return;
    }

    // ios mobile fix
    if ($whereIsMyOrder.hasClass('hidden')) {
        $whereIsMyOrder.wrap('<span>');
        $itemFaulty.wrap('<span>');
        $cancelOrder.wrap('<span>');
        $discussReturn.wrap('<span>');
        $missingItem.wrap('<span>');
        $helpOption.wrap('<span>');
    } else {
        $whereIsMyOrder.unwrap();
        $itemFaulty.unwrap();
        $cancelOrder.unwrap();
        $discussReturn.unwrap();
        $missingItem.unwrap();
        $helpOption.unwrap();
    }

}

function toggleNoRelatedPurchaseFields(){
    var $generalQuery = $('[value="generalQuery"]');
    var $thinkingPurchasing = $('[value="thinkingPurchasing"]')

    $generalQuery.toggleClass('hidden');
    $thinkingPurchasing.toggleClass('hidden');

    if (!layout.isMobile()) {
        return;
    }

        // ios mobile fix
    if ($generalQuery.hasClass('hidden')) {
        $generalQuery.wrap('<span>');
        $thinkingPurchasing.wrap('<span>');
    } else {
        $generalQuery.unwrap();
        $thinkingPurchasing.unwrap();
    }
}

function toggleFields() {
    var $questionSelect = $('#dwfrm_contactusemail_questionOptions');
    $questionSelect.val('');

    toggleRelatedPurchaseFields();
    toggleNoRelatedPurchaseFields();

    formPrepare.init({
        continueSelector: '[name$="dwfrm_contactusemail_send"]',
        formSelector: 'form[id$="contactUsEmailForm"]'
    });
}

module.exports = {
    init: function() {
        initializeCache();
        initializeEvents();
    },
    initOpen: function() {
        var $isPurchaseRelated = $('#dwfrm_contactusemail_isPurchaseRelated');
        $('[value="pleaseSelectPurchaseRelated"]').attr('disabled', 'disabled');

        if ($isPurchaseRelated.val() === "true") {
            toggleNoRelatedPurchaseFields();
        } else {
            toggleRelatedPurchaseFields()
        }


        var maxHeigth = $(window).height() - 100;
        $('.contact-us-modal .blockWithScroll').css('max-height', maxHeigth);
        $('.f-field-required_indicator').css('color', 'red');

        formPrepare.init({
            continueSelector: '[name$="dwfrm_contactusemail_send"]',
            formSelector: 'form[id$="contactUsEmailForm"]'
        });
    }
}
