'use strict';

var sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null),
    _ = require('lodash');

var initialized = false;

//Function for 'shrinking' header and hide searchbar
var $header = $('.js-sticky_header'),
    $headerWrapper = $('.js-header'),
    $currentElement = $(document.activeElement),
    $headerPromo = $('.js-header-light-promo'),
    headerBottomPadding = 25,
    root = document.documentElement;

if($('.js-plp-filters').length) {
    var $plpFiltersTop = $('.js-plp-filters').offset().top
}


function initializeEvents() {
    $(document).on('window.resize', function () {
        $(document).trigger('stickykit.update');
        $(document).trigger('layout.reload');
    });

    $(document).on('window.modechanged', function () {
        $('.js-sticky_element').trigger('sticky_kit:detach');
        $(document).trigger('stickykit.update');
    });

    $(document).on('scroll', _.debounce(shrinkHeader, 50));
    $(document).on('scroll', function(){
        addFixedClass();
        addFixedToPlpFilters()
    });
    $(document).on('search.update', function (e, params) {
        addFixedToPlpFilters()
    });
}

var stickyheader = {
    init: function () {
        if (initialized) {
            return;
        }

        addFixedClass();
        shrinkHeader();
        setBodyTopPadding();
        scrollBottomHideClassAdd();

        initializeEvents();
        initialized = true;
    },
    

    headerPromoClose: function () {
        shrinkHeader();
        setBodyTopPadding();
    }
};

function shrinkHeader() {

    if ($header.length === 0) {
        return;
    }

    if ($currentElement.closest('.js-header_search').length) {
        return;
    }

    if($headerPromo.length) {
        $header.css('top', $headerPromo.height() + "px");
    }

    if ($header.offset().top > sitePreferences.HEADER_SHRINK_OFFSET_DISTANCE && !$header.hasClass('shrink') ) {
        $header.addClass('shrink');
        $headerWrapper.addClass('shrink');
    } else if ($header.offset().top < sitePreferences.HEADER_SHRINK_OFFSET_DISTANCE && $header.hasClass('shrink')) {
        $header.removeClass('shrink');
        $headerWrapper.removeClass('shrink');
    }
}

function setBodyTopPadding() {
    if($headerPromo.length) {
        var headerAndPromoHeight = $header.height() + $headerPromo.height() + headerBottomPadding + "px";

        root.style.setProperty("--body-padding", headerAndPromoHeight);
    }
}

function scrollBottomHideClassAdd() {
    var oldScrollY = window.scrollY;
    var headerLight = document.querySelector('.js-header-light');

    if (typeof(headerLight) != 'undefined' && headerLight != null) {
        window.onscroll = function(e) {
            if(oldScrollY < window.scrollY && window.scrollY > 3) {
                headerLight.classList.add('l-light-header--hide');
            } else {
                headerLight.classList.remove('l-light-header--hide');
            }

            oldScrollY = window.scrollY;
        }
    }
}

function addFixedClass() {
    var header = document.getElementById('main-header');
    var midHeader = document.querySelector('.l-header_top');
    if (typeof(header) != 'undefined' && header != null) {
        if (window.scrollY > 3) {
            header.classList.add("header-fixed");
        } else {
            header.classList.remove("header-fixed");
        }
    }

    if (typeof(midHeader) != 'undefined' && midHeader != null) {
        if (window.scrollY > 3) {
            midHeader.classList.add("header-middle");
        } else {
            midHeader.classList.remove("header-middle");
        }
    }
}

function addFixedToPlpFilters() {
    var windowTop = $(window).scrollTop();

    if(windowTop >= $plpFiltersTop) {
        $('.js-plp-filters').addClass('filters-fixed');
    } else {
        $('.js-plp-filters').removeClass('filters-fixed');
    }
}

module.exports = stickyheader;

