'use strict';

var $               = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    ajax            = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
    progress        = require('./../../../../../app_storefront_core/cartridge/js/progress'),
    util            = require('./../../util'),
    urls            = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    sessionAttributes = (typeof window !== "undefined" ? window['SessionAttributes'] : typeof global !== "undefined" ? global['SessionAttributes'] : null),
    constants       = require('./constants'),
    cssConstants    = require('./../../cssconstants');

var $cache = {},
    currentData,
    initialized = false,
    isUsed = false;

function initializeCache() {
    $cache.document         = $(document);
    $cache.paymentHeader    = $cache.document.find(constants.CLASS_PAYMENT_HEADER);
    $cache.container        = $cache.document.find(constants.CLASS_VP_CONTAINER);
    // actions
    $cache.redeemPoints     = $cache.container.find(constants.CLASS_VP_REDEEM);
    $cache.usePoints        = $cache.container.find(constants.CLASS_VP_USE);
    $cache.removePoints     = $cache.container.find(constants.CLASS_VP_REMOVE);
    // containers
    $cache.pointsInput      = $cache.container.find(constants.CLASS_VP_INPUT);
    $cache.pointsInputAfter = $cache.container.find(constants.CLASS_VP_INPUT_AFTER);
    $cache.checkContainer   = $cache.container.find(constants.CLASS_VP_CHECK_CONTAINER);
    $cache.resultWrapper    = $cache.container.find(constants.CLASS_VP_RESULT_WRAPPER);
    $cache.resultMsg        = $cache.container.find(constants.CLASS_VP_RESULT);
    $cache.useMsgWrapper    = $cache.container.find(constants.CLASS_VP_USE_MSG_WRAPPER);
    isUsed                  = $cache.container.data('isUsed');
}

function initializeEvents() {
    $cache.usePoints.on('click', useVipPoints);
    $cache.removePoints.on('click', removeVipPoints);
    $cache.pointsInput.on('change keyup', calculateMoney);
    $cache.document.on('checkout.updateinstruments', renewBalance);
}

function initVipBalance() {
    initialized = true;

    var url = urls.vpGetBalance;
    ajax.getJson({
        url: url,
        callback: function (response) {
            currentData = response;
            if (!response.success) {
                $cache.container.html(response.message);
                return;
            }
            $cache.resultWrapper.removeClass(cssConstants.G_HIDDEN);
            if (!isUsed && !response.points) {
                $cache.redeemPoints.addClass(cssConstants.G_HIDDEN);
            }
            $cache.resultMsg.html(response.message);
            if (!isUsed && response.pointsNeeded == 0 && $cache.redeemPoints.hasClass('expanded')) {
                $cache.redeemPoints.trigger('click');
            }
            if (!response.points || !response.pointsNeeded) {
                $cache.checkContainer.addClass(cssConstants.G_HIDDEN);
            }
            $cache.paymentHeader[response.isFullOrder ? 'addClass' : 'removeClass'](cssConstants.G_HIDDEN);
            $cache.container.toggleClass(cssConstants.M_FULLORDER, response.isFullOrder);
            $cache.pointsInput.val('');
            $cache.pointsInputAfter.html('');
        }
    });
}

function useVipPoints(e) {
    e.preventDefault();
    var points = $cache.pointsInput.val();
    if (isNaN(points) || 0 == points) {
        return;
    }

    var me = $(this);
    var url = urls.vpUsePoints;
    var form = $(this).closest('form');
    //TODO: if more them needed change to needed value
    var options = {
            url: url,
            method: 'POST',
            cache: false,
            dataType: "json",
            data: $(this).closest('fieldset').serialize() || form.serialize()
        };

    $.ajax(options).done(function (response) {
        $cache.useMsgWrapper.removeClass(cssConstants.G_HIDDEN);
        $cache.removePoints.removeClass(cssConstants.G_HIDDEN);
        $cache.useMsgWrapper.find(constants.CLASS_VP_USE_MSG).html(response.message).removeClass(cssConstants.M_ERROR);
        if(response.success) {
            isUsed = true;
            $cache.document.trigger('checkout.updatesummary');
            $cache.document.trigger('checkout.updateinstruments', response);
        } else {
            $cache.removePoints.addClass(cssConstants.G_HIDDEN);
            $cache.useMsgWrapper.find(constants.CLASS_VP_USE_MSG).addClass(cssConstants.M_ERROR)
            $cache.pointsInput.val(currentData.pointsNeeded).trigger('change');
        }
    });
}

function removeVipPoints(e) {
    e.preventDefault();
    var me = $(this);
    var url = urls.vpRemovePoints;
    ajax.getJson({
        url: url,
        callback: function (response) {
            if(response.success) {
                isUsed = false;
                $cache.useMsgWrapper.find(constants.CLASS_VP_USE_MSG).html('');
                $cache.useMsgWrapper.addClass(cssConstants.G_HIDDEN);
                $cache.removePoints.addClass(cssConstants.G_HIDDEN);
                $cache.checkContainer.removeClass(cssConstants.G_HIDDEN);
                $cache.document.trigger('checkout.updatesummary');
                $cache.document.trigger('checkout.updateinstruments', response);
            }
        }
    });
}

function calculateMoney(e) {
    var me = $(this);
    var price = me.val() * currentData.multiplier / 100;
    var formattedPrice = price.toFixed(2);

    $cache.pointsInputAfter.html('= ' + sessionAttributes.CURRENCY_SYMBOL + formattedPrice);
}


function renewBalance(e, eventData) {
    e.preventDefault();
    if (eventData.payNeeded || isUsed) {
        $cache.container.removeClass(cssConstants.G_HIDDEN);
    } else if (!eventData.payNeeded && (!initialized || !isUsed)) {
        $cache.container.addClass(cssConstants.G_HIDDEN);
        return;
    }
    if (eventData.isFullOrder) {
        $cache.paymentHeader.addClass(cssConstants.G_HIDDEN);
    }
}

var vippoints = {
        init : function (params) {
            initializeCache();
            initializeEvents(params);
            initVipBalance();
        }
    };

module.exports = vippoints;
