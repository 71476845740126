'use strict';

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var quickview = require('./../../quickview');
var cookie = require('./../../cookie');
var zoom = require('./zoom');
var carousel = require('./../../carousel');
var layout = require('./../../layout');
var sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null);
var Constants = require('./constants');
var CssConstants = require('./../../cssconstants');
var addtobagmobile = require('./../../addtobagmobile');
var util = require('./../../util');
var progress = require('./../../progress');

var $cache = {},
    initialized = false,
    options = {
        //containers
        productContainer: Constants.CLASS_PRD_CONTAINER,
        productImagesContainer: '.js-product-images_container',
        //view type switch
        viewContainer: Constants.CLASS_PRD_SWITCH,
        frontViewClass: Constants.CLASS_PRD_SWITCH_FRONT,
        backViewClass: Constants.CLASS_PRD_SWITCH_BACK,
        //image
        imageMain: Constants.CLASS_PRD_IMAGES_MAIN,
        imageSize: Constants.CLASS_PRD_IMAGES_SIZE,
        imageContainer: Constants.CLASS_PRD_IMAGES_CONTAINER,
        imageSwitchContainer: Constants.CLASS_PRD_IMAGES_SWITCH,
        //thumbs
        thumbItems: Constants.CLASS_PRD_THUMBS,
        thumbImage: Constants.CLASS_PRD_THUMB,
        thumbAnchor: Constants.CLASS_PRD_THUMB_ANCHOR,
        thumbCarousel: Constants.CLASS_PRD_THUMB_CAROUSEL,
        //size switch
        adSize: Constants.CLASS_PRD_IMAGES_SWITCH_AD,
        ddgSize: Constants.CLASS_PRD_IMAGES_SWITCH_DDG,
        //Bra size grid
        braSizeGridFirst: Constants.CLASS_PRD_BRA_SIZE_GRID_FIRST,
        braSizeGridSecond: Constants.CLASS_PRD_BRA_SIZE_GRID_SECOND,
        braSizeGridFirstPDP: Constants.CLASS_PRD_BRA_SIZE_GRID_FIRST_PDP,
        braSizeGridSecondPDP: Constants.CLASS_PRD_BRA_SIZE_GRID_SECOND_PDP,
        //states
        activeClass: CssConstants.M_ACTIVE,
        //Images popup
        hiddenClass: CssConstants.G_HIDDEN,
        bodyZoom: CssConstants.C_ZOOM,
        productImage: Constants.CLASS_PRD_IMAGE,
        productImagesPopupHidden: Constants.CLASS_PRD_IMAGES_POPUP_HIDDEN,
        productImagesPopupBody: Constants.CLASS_PRD_IMAGES_POPUP_BODY,
        productImagesPopupClose: Constants.CLASS_PRD_IMAGES_POPUP_CLOSE,
        productImagesCarousel: '.js-carousel',
        slickInitialized: 'slick-initialized',
        slickActive: 'slick-active',
        slickPrev: 'slick-prev',
        slickNext: 'slick-next',
        videoContainer: '.js-video_container',
        mainImageContainer: '.js-main_images_container',
        slickActiveClass: '.slick-active',
        productTileCarousel : '.js-carousel-product-tile',
        productMainImage: '.b-product_images-main_image'
    },
    BRASIZE_COOKIE = 'brasize',
    BRASIZE_COOKIEPDP = 'brasizepdp',
    VIEWTYPE_COOKIE = 'prodview',
    BRASIZE_TABS = {
        AD: 'ad',
        DDG: 'ddg'
    },
    VIEWTYPE = {
        FRONT: 'front',
        BACK: 'back'
    };

function initializeParams (params) {
    options = $.extend(true, {}, options, params);
};

function initializeCache() {
    $cache.document = $(document);
    $cache.swither = $cache.document.find(options.viewContainer);
};

function initializeDOM() {
    if (window.pageContext.ns !== 'search') {
        //update thumbnails slider
        updateThumbnailsSlider();
    }

    if (window.pageContext.ns === 'search') {
        updateTiles();
    }
};

/*
 * Back/Front View Types
 */
function switchBraSizeGrid() {
    if (getSize() === BRASIZE_TABS.DDG) {
        $(options.braSizeGridSecond).prop('checked',false);
        triggerClickSizeSwitcher(false, $(options.braSizeGridSecond), $(options.braSizeGridSecondPDP));
        setSize(BRASIZE_TABS.DDG);
        updateTiles();
        $(options.braSizeGridSecond).prop('checked',true);
        if (layout.isMobile()) {
            $(options.braSizeGridSecondPDP).prop('checked',true);
        }
    }
}

/*
 * AD/DDG Sizes
 */
function getImagesBySize (data, size) {
    if (size === BRASIZE_TABS.DDG) {
        return data[1] || data[0];
    }
    return data[0];
};

function getSize () {
    if (!$cache.size) {
        $cache.size = cookie.get(options.mode === 'pdp' ? BRASIZE_COOKIEPDP : BRASIZE_COOKIE);

        if (!$cache.size) {
            $cache.size = BRASIZE_TABS.AD;
        }
    }
    return $cache.size;
};

function setSize (size) {
    $cache.size = size;
    cookie.set(BRASIZE_COOKIEPDP, size);
    if (options.mode !== 'pdp') {
        cookie.set(BRASIZE_COOKIE, size);
    }
};

function isCurrentSize ($size) {
    if (getSize() === BRASIZE_TABS.DDG) {
        return $size.hasClass(options.ddgSize.substr(1));
    }
    return $size.hasClass(options.adSize.substr(1));
};

function getData ($container) {
    var node = $container.get(0);
    if (!node.imagesData) {
        var jsonData = $container.find(options.imageSwitchContainer + ' script');
        if (jsonData && jsonData.length) {
            node.imagesData = JSON.parse(jsonData[0].innerHTML);
        }
    }
    return node.imagesData;
};

function updateImage ($container, data) {
    if (data) {
        updateMainImage($container, data);
        if (options.updateSize) {
            $container.find(options.imageSize).text(data.size);
        }
    }
};

function updateMainImage ($container, data) {
    if (data) {
        var $mainImg =  $($container.find(options.imageMain).get(0)),
            $img = $mainImg.find('img');

        $img.attr('src', data.url);
        if (data.title) {
            $img.attr('title', data.title);
        }
        if (data.alt) {
            $img.attr('alt', data.alt);
        }
        //update hi-res image
        if (data.hires && $container.hasClass(Constants.CLASS_PRD_IMAGES_ZOOM)) {
            $mainImg.attr('href', data.hires.url);
        }
        //update zoom widget
        zoom.init();
    }
};

function hoverTile($container, eventType) {
    var data = getData($container);
    if (data) {
        data = getImagesBySize(data, getSize());

        switch (eventType) {
            case 'mouseenter':
                data = (data[2] ? data[2] : data[1]) || data[0];
                break;
            case 'mouseleave':
                data = data[0] || data[1];
                break;
            default:
                return;
        }
        updateImage($container, data);
    }
};

function updateTile($tile) {
    var size = getSize();
    var slider;

    if (window.pageContext.ns === 'search') {
        var $carousel = $tile.find(options.productTileCarousel).eq(0);

        initProductTileCarousel($carousel);

        var hasImageChangeSize = $carousel.data('hasAllImgTypes');

        if ($carousel.length && hasImageChangeSize) {
            $carousel.slick('slickUnfilter');
            $carousel.slick('slickFilter', function() {
                return $(this).find('.b-product_images-main_image').data('typeImg') === size;
            });
        }
    } else if (layout.isMobile() || layout.isTablet() || layout.isDesktop() || !$tile.hasClass(Constants.CLASS_PRD_IMAGES_SLIDER)) {
        var data = getData($tile);
        if (data) {
            data = getImagesBySize(data, size);
            updateImage($tile, (!$tile.hasClass(Constants.CLASS_PRD_IMAGES_VIEWTYPE)) ? data[0] : data[1]);
            if ($tile.hasClass(Constants.CLASS_PRD_IMAGES_THUMB)) {
                var $containerThumbs = $tile.find(options.thumbItems);
                $containerThumbs.removeClass(options.activeClass);
                if (size === BRASIZE_TABS.DDG && $containerThumbs.length > 1) {
                    $containerThumbs.eq(1).addClass(options.activeClass);
                } else {
                    $containerThumbs.eq(0).addClass(options.activeClass);
                }
            }
        }
    }

    if ($tile.hasClass('js-slider')) {
        slider = $tile.find('.js-carousel');
        if (!slider.hasClass('slick-initialized')) {
            carousel.initCarousel(slider);
        }
        slider.removeClass(CssConstants.M_SHOW);
        if (size === BRASIZE_TABS.DDG && slider.length > 1) {
            slider.eq(1).addClass(CssConstants.M_SHOW);
        } else {
            slider.eq(0).addClass(CssConstants.M_SHOW);
        }
    }
    //update thumbnails slider
    updateThumbnailsSlider();
    //update tabs
    updateTileActiveTab($tile);
}

function updateTiles(containers) {
    (containers || $cache.document.find(options.imageContainer)).each(function() {
        updateTile($(this));
    });
};

function triggerClickSizeSwitcher(clickTriggered, $sizeTarget, $sizeTargetPDP) {
    if (clickTriggered) return;
    if ($sizeTarget && $sizeTarget.length) {
        $sizeTarget.trigger('click', [true]);
    }
    if ($sizeTargetPDP && $sizeTargetPDP.length && layout.isMobile()) {
        $sizeTargetPDP.trigger('click', [true]);
    }
};

function updateSizeGrid() {
    var size = getSize();
    $cache.document.find(options.braSizeGridFirst + ',' + options.braSizeGridFirstPDP).prop('checked', !(size === BRASIZE_TABS.DDG));
    $cache.document.find(options.braSizeGridSecond + ',' + options.braSizeGridSecondPDP).prop('checked', (size === BRASIZE_TABS.DDG));
};

function updateTileActiveTab($tile) {
    var size = getSize();

    $(options.imageSwitchContainer + ' li').removeClass(options.activeClass);
    $('.b-product_tile-size_switches li').removeClass(options.activeClass);
    if (size === BRASIZE_TABS.DDG) {
        $(options.ddgSize).addClass(options.activeClass);
    } else {
        $(options.adSize).addClass(options.activeClass);
    }
};

function showImagesPopup() {
    if (sitePreferences.PDP_POPUP_ENABLED && layout.isIPadDevice()) {
        var $slider = $(options.productImagesPopupHidden).find(options.productImagesCarousel);
        if ($slider && !$slider.hasClass(options.slickInitialized)) {
            carousel.initCarousel($slider);
        }
        if ($slider) {
            $slider.slick('refresh');
        }

        setTimeout(function() {
            $(options.productImagesPopupBody).addClass(options.bodyZoom);
            //update thumbnails slider
            updateThumbnailsSlider();
        }, 0);
    }
};

function hideImagesPopup() {
    if (sitePreferences.PDP_POPUP_ENABLED && layout.isIPadDevice()) {
        //update thumbnails slider
        updateThumbnailsSlider();

        $(options.productImagesPopupBody).removeClass(options.bodyZoom);
    }
};

function updateThumbnailsSlider() {
    if (layout.isMobile()) {
        return;
    }

    $(options.thumbCarousel).each(function() {
        var $this = $(this);

        var thumbs = $this.find('img[src]');
        var thumbsNumber = thumbs.length;
        var thumbsCount = 0;

        var imageLoadedHandler = function() {
            // increase until all unique thumbs loaded to trigger Slick-resize
            thumbsCount++;

            if (thumbsCount === thumbsNumber) {
                if ($this && !$this.hasClass(options.slickInitialized)) {
                    carousel.initCarousel($this);
                }

                if ($this) {
                    $this.slick('refresh');
                }

                $this.find('.b-product_images-thumbnail').eq(0).addClass('slick-current');
            }
        }

        thumbs.each(function() {
            var thumb = this;

            thumb.onerror = imageLoadedHandler
            $(thumb).on('load', imageLoadedHandler);

        });
    });
};

function closeImagesPopup(e) {
    if (sitePreferences.PDP_POPUP_ENABLED && layout.isIPadDevice()) {
        if (e.keyCode === 27 && !$(options.productImagesPopupHidden).hasClass(options.hiddenClass)) {
            $(options.productImagesPopupClose).trigger('click');
        }
    }
};

function updateCarouselImages(e, triggeredElement, direction, fromVideo, imageContainer) {
    var videoContainer = $(options.videoContainer);
    videoContainer[0].innerHTML = '';
    var target = (triggeredElement
        ? $(e.target).closest(options.imageContainer + ' ' + options.productImagesCarousel)
        : imageContainer);
    var videoUrl =  $(e.target).find(options.slickActiveClass).find(options.productMainImage).data('videoUrl');
    var videoHeight = '628px';
    if (layout.isMobile()) {
        videoHeight = '531px';
        videoUrl =  $(e.target).find(options.slickActiveClass).find(options.productMainImage).data('mobileVideoUrl');
    }
    if (layout.isTablet()) {
        if (window.innerWidth < 821) {
            videoHeight = '395px';
            videoUrl =  $(this).data('mobileVideoUrl');
        } else {
            videoHeight = '627px';
        }
    }
    if (e.type === 'swipe' && videoUrl) {
        insertVideo(videoUrl, $(e.target).find(options.slickActiveClass).find(options.productMainImage).data('controls'), videoHeight);
    }

    if (fromVideo) {
        var slideno = imageContainer.find('.' + options.slickActive).data('slickIndex');

        switch(direction) {
            case 'left':
                slideno --;
                break;
            case 'right':
                slideno ++;
                break;
        }

        $(options.imageContainer + ' ' + options.productImagesCarousel).slick('slickGoTo', slideno);

        return;
    } else {
        var isVideo = target.find(options.slickActiveClass) && target.find(options.slickActiveClass).find(options.productMainImage).data('isVideo') ? target.find(options.slickActiveClass).find(options.productMainImage).data('isVideo') : null;
        if(target && isVideo)  {
            var videoUrl = target.find(options.slickActiveClass).find(options.productMainImage).data('videoUrl');
            var videoHeight = '628px';
            if (layout.isMobile()) {
                videoHeight = '531px';
                videoUrl =  target.find(options.slickActiveClass).find(options.productMainImage).data('mobileVideoUrl');
            }
            if (layout.isTablet()) {
                if (window.innerWidth < 821) {
                    videoHeight = '395px';
                    videoUrl =  $(this).data('mobileVideoUrl');
                } else {
                    videoHeight = '627px';
                }
            }
            insertVideo(videoUrl, target.find(options.slickActiveClass).find(options.productMainImage).data('controls'), videoHeight);
        }
    }

    if (layout.isIPadDevice() || layout.isDesktop()) {
        var slideno = -1

        //update images carousels
        if ( target.find('.' + options.slickActive).length
            && (typeof(target.find('.' + options.slickActive).data('slickIndex')) !== 'undefined')
        ) {
            slideno = target.find('.' + options.slickActive).data('slickIndex');
            $(options.imageContainer + ' ' + options.productImagesCarousel).slick('slickGoTo', slideno);
        }

        //update thumbnails carousel
        if (slideno > -1) {
            $(options.imageContainer + ' ' + options.thumbCarousel).slick('slickGoTo', slideno);
        }

        // add active class to selected thumb image
        var $thumbsCarousels = $('.js-product-images_thumbs');
        if ($thumbsCarousels.length) {
            $thumbsCarousels.each(function() {
                var $carousel = $(this);
                var $thumbs = $carousel.find('.b-product_images-thumbnail');
                $thumbs.each(function(index) {
                    var $thumb = $(this);

                    if ($thumb.hasClass('slick-current') && slideno !== index) {
                        $thumb.removeClass('slick-current');
                        return;
                    }

                    if (slideno === index) {
                        $thumb.addClass('slick-current');
                    }
                });
            })
        }
    }
};

function insertVideo(videoUrl, controls, videoHeight) {
    var imgContainer = $(options.mainImageContainer);
    var videoContainer = $(options.videoContainer);
    videoContainer[0].innerHTML = '';
    var buttonNext = document.createElement('button');
    var buttonPrev = document.createElement('button');
    buttonPrev.setAttribute('class', 'video-prev slick-prev slick-arrow');
    buttonNext.setAttribute('class', 'video-next slick-next slick-arrow');
    buttonPrev.setAttribute('aria-label', 'Previous');
    buttonNext.setAttribute('aria-label', 'Next');
    buttonPrev.type = 'button';
    buttonNext.type = 'button';

    var videlem = document.createElement("video");
    if (videoHeight != '628px') {
        videlem.setAttribute('playsinline','');
    }

    var videoWidth = '360px';
    switch (videoHeight) {
        case '628px' :
            videoWidth = '360px';
            break;
        case '531px' :
            videoWidth = '394px';
            break;
        case '395px' :
            videoWidth = '315px';
            break;
        case '627px' :
            videoWidth = '465px';
            break;
        default:
            videoWidth = '360px';
    }

    videlem.autoplay = true;
    if(controls && videoHeight != '531px') {
        videlem.controls = true;
    }
    var sourceMP4 = document.createElement("source");
    sourceMP4.type = "video/mp4";
    sourceMP4.src = videoUrl;
    videlem.appendChild(sourceMP4);
    videlem.setAttribute('height', videoHeight);
    videlem.setAttribute('width', videoWidth);
    videlem.setAttribute('onload', 'videoLoaded()')

    imgContainer[0].classList.add('hidden');
    videoContainer[0].classList.remove('hidden');
    videoContainer[0].appendChild(buttonPrev);
    videoContainer[0].appendChild(videlem);
    videoContainer[0].appendChild(buttonNext);

    if (videoWidth!='315px') {
        videlem.addEventListener('loadeddata', function(e) {
            $(e.target).removeAttr('width');
            $(e.target).removeAttr('height');
        }, false);
    }

    setTimeout(function () {
        $cache.document.on('click', '.video-prev', function (e) {
            imgContainer[0].classList.remove('hidden');
            videoContainer[0].classList.add('hidden');
            updateCarouselImages(e, false, 'left', true, imgContainer);
        }).on('click', '.video-next', function (e) {
            imgContainer[0].classList.remove('hidden');
            videoContainer[0].classList.add('hidden');
            updateCarouselImages(e, false, 'right', true, imgContainer);
        }).on('click', '.js-video_container', function (e) {
            stopVideo();
        });
    }, 1000);
}

function stopVideo() {
    var video = $('video');
    if (video.length && $(video[0]).attr('pause') === 'false') {
        video[0].pause();
        video[0].setAttribute('pause', true);
	} else {
        video[0].play();
        video[0].setAttribute('pause', false);
    }
}

function initializeEvents() {
    $cache.document.on('click', options.adSize, function(e, clickTriggered) {
        if (isCurrentSize($(this))) return;
        triggerClickSizeSwitcher(clickTriggered, $(options.braSizeGridFirst), $(options.braSizeGridFirstPDP));
        setSize(BRASIZE_TABS.AD);
        updateTiles();
    }).on('click', options.ddgSize, function(e, clickTriggered) {
        if (isCurrentSize($(this))) return;
        triggerClickSizeSwitcher(clickTriggered, $(options.braSizeGridSecond), $(options.braSizeGridSecondPDP));
        setSize(BRASIZE_TABS.DDG);
        updateTiles();
    }).on('click', [options.braSizeGridFirst, options.braSizeGridFirstPDP].join(','), function(e, clickTriggered) {
        if (window.pageContext.ns === 'search') {
            setSize(BRASIZE_TABS.AD);
            updateTiles();

            return;
        }

        triggerClickSizeSwitcher(clickTriggered, $(options.adSize));
    }).on('click', [options.braSizeGridSecond, options.braSizeGridSecondPDP].join(','), function(e, clickTriggered) {
        if (window.pageContext.ns === 'search') {
            setSize(BRASIZE_TABS.DDG);
            updateTiles();

            return;
        }

        triggerClickSizeSwitcher(clickTriggered, $(options.ddgSize));
    }).on('click', '.js-quickview', function(e) {
        e.preventDefault();

        var urlQuickview = $(this).attr('data-url-quickview');

        progress.show();
        quickview.show({
            url: urlQuickview,
            source: 'quickview',
            callback: function() {
                progress.hide();
            }
        });

        dynamicYield.callSPAEvent({
            context: {
                type: "PRODUCT",
                data: [$(this).closest('.js-b-product_tile').data('itemid') || $(this).parentsUntil('.js-product-set-item').find('span[itemprop="productID"]').text()]
            },
            url: $(this).attr('href'),
            countAsPageview: true
        });
    }).on('click', '.js-add-to-bag-mobile', function(e) {
        e.preventDefault();
        var url= $(e.target).attr('data-url-addtocart');

        progress.show();
        addtobagmobile.show({
            url: url,
            source: 'quickview',
            callback: function() {
                progress.hide();
            }
        });
    })

    $cache.document.on('mouseenter mouseleave', options.imageMain, function(event) {
        var container = $(this).closest(options.imageContainer);
        if (container.hasClass(Constants.CLASS_PRD_IMAGES_SWAP)) {
            hoverTile(container, event.type);
        }
    });

    $cache.document.on('click', options.imageMain, function (e) {
        if ($(this).closest(options.imageContainer).hasClass(Constants.CLASS_PRD_IMAGES_CLICK)) {
            e.preventDefault();
        } else {
            setSize(getSize());
        }
    });

    $cache.document.on('click', options.thumbImage, function (e) {
        e.preventDefault();
        if ($(this).data('isVideo')) {
            var videoUrl =  $(this).data('videoUrl');
            var videoHeight = '628px';
            if (layout.isMobile()) {
                videoHeight = '531px';
                videoUrl =  $(this).data('mobileVideoUrl');
            }
            if (layout.isTablet()) {
                if (window.innerWidth < 821) {
                    videoHeight = '395px';
                    videoUrl =  $(this).data('mobileVideoUrl');
                } else {
                    videoHeight = '627px';
                }
            }
            insertVideo(videoUrl, $(this).data('controls'), videoHeight);
        } else {
            var imgContainer = $(options.mainImageContainer);
            var videoContainer = $(options.videoContainer);
            videoContainer[0].innerHTML = '';
            imgContainer[0].classList.remove('hidden');
            videoContainer[0].classList.add('hidden');

            var slideno = $(this).data('index');
            $(options.imageContainer + ' ' + options.productImagesCarousel).slick('slickGoTo', slideno);

            // Add classes to selected thumb image
            var $thumbCarousel = $(this).closest(options.thumbCarousel).eq(0);
            if ($thumbCarousel.data('slick')) {
                var slideThumbno = $(this).closest('.slick-slide').data('slick-index');
                var $slides = $thumbCarousel.find('.slick-slide');

                var slideZeroIndex = 0;
                $slides.each(function(index) {
                    var $slide = $(this);
                    if ($slide.data('slick-index') === 0) {
                        slideZeroIndex = index;
                    }
                })

                $slides
                    .removeClass('slick-active slick-center slick-current')
                    .attr('aria-hidden', 'true');

                $slides
                    .eq(slideThumbno + slideZeroIndex)
                    .addClass('slick-current');
            } else {
                var $thumbsCarousel = $(this).closest('.js-product-images_thumbs').eq(0);
                var $thumbs = $thumbsCarousel.find('.b-product_images-thumbnail');
                $thumbs.each(function(index) {
                    var $thumb = $(this);

                    if ($thumb.hasClass('slick-current') && slideno !== index) {
                        $thumb.removeClass('slick-current');
                        return;
                    }

                    if (slideno === index) {
                        $thumb.addClass('slick-current');
                    }
                });
            }
        }
    });

    $cache.document.on('click', options.thumbAnchor, function (e) {
        e.preventDefault();
    });

    $cache.document.on('click', options.productImage, function (e) {
        e.preventDefault();
        showImagesPopup();
    }).on('click', options.productImagesPopupClose, function (e) {
        e.preventDefault();
        hideImagesPopup();
    }).on('keyup', function(e) {
        e.preventDefault();
        closeImagesPopup(e);
    }).on('swipe', options.imageContainer + ' ' + options.productImagesCarousel, function (e, slick, direction) {
       updateCarouselImages(e, false, direction);
    }).on('click', options.imageContainer + ' ' + options.productImagesCarousel + ' .' + options.slickPrev, function (e) {
        updateCarouselImages(e, true, 'right');
    }).on('click', options.imageContainer + ' ' + options.productImagesCarousel + ' .' + options.slickNext, function (e) {
        updateCarouselImages(e, true, 'left');
    }).on('images.carousel-images-resize', '', function(e, container) {
        resizeCarouselImages(container);
    });

    $(window).on('orientationchange', function(){
        setTimeout(function() {
            //update thumbnails slider
            updateThumbnailsSlider();
        }, 0);
    });
};

function resizeCarouselImages(container) {
    var $container = $(container);

    setTimeout(function() {
        if ($container.length && $container.find('.slick-list').height() < 200) {
            var imgList = $container.find('.slick-list').toArray();
            if (imgList.length) {
                imgList.forEach(function (list) {
                    var $this = $(list);
                    var parentWidth = $this.parent().width();
                    var containerHeight = $this.closest('.js-product-images_container').height();

                    $this.width(parentWidth);
                    $this.height(containerHeight);
                });
            }

            var $slides = $container.find('.slick-slide');
            if ($slides.width() < 25) {
                var slides = $slides.toArray();
                if (slides.length){
                    slides.forEach(function (slide) {
                        var $slide = $(slide);
                        var parentWidth = $slide.closest('.slick-list').width();

                        $slide.width(parentWidth);
                    });
                }
            }

            $container.slick('setPosition');
            $container.slick('resize');
        }
    }, 1000);
}

$(document).ready(function() {
    if (!util.isMobile()) {
        var sliders = $('.js-thumbnails-carousel');
        if (sliders.length) {
            sliders.toArray().forEach(function (slider) {
                carousel.initCarousel($(slider));
            });
        }

        var $thumbsCarousel = $('#js-product_images-thumbs_container');
        if ($thumbsCarousel.length) {
            resizeCarouselImages($thumbsCarousel);
        }
    }

    initProductTileCarousels();
});

function initProductTileCarousel($slider) {
    if (
        !$slider
        || ($slider && !$slider.length)
        || ($slider && $slider.hasClass('slick-initialized'))
    ) {
        return;
    }

    carousel.initCarousel($slider);

    var touchStartEvents = new Map();
    $slider.on('touchstart mousedown', function(e) {
        e.preventDefault();

        if ($(e.target).hasClass('slick-arrow')) {
            return;
        }

        var $this = $(this);
        var dataItemID = $this.closest(options.productImagesContainer).eq(0).data('itemid');

        touchStartEvents.set(dataItemID, false);
    });

    $slider.on('touchmove mousemove', function(e) {
        e.preventDefault();

        var $this = $(this);
        var dataItemID = $this.closest(options.productImagesContainer).eq(0).data('itemid');

        if (touchStartEvents.has(dataItemID)) {
            touchStartEvents.set(dataItemID, true);
        }
    });

    $slider.on('touchend mouseup', function(e) {
        e.preventDefault();

        if ($(e.target).hasClass('slick-arrow')) {
            return;
        }

        var $this = $(this);
        var dataItemID = $this.closest(options.productImagesContainer).eq(0).data('itemid');

        if (touchStartEvents.has(dataItemID) && touchStartEvents.get(dataItemID) === false) {
            var url = $this.data('productUrl');
            window.location.href = url;
        }

        touchStartEvents.delete(dataItemID);
    });
}

/**
 * Init all product tile carousels img ('.js-carousel-product-tile')
 */
function initProductTileCarousels() {
    var showAutoSwipeProductTileOnPLP = sessionStorage.getItem('showAutoSwipeProductTileOnPLP');

    var $productTileSliders = $(options.productTileCarousel).not('.slick-initialized');
    $productTileSliders.toArray().forEach(function (slider, index) {
        var $slider = $(slider);
        initProductTileCarousel($slider);

        // callback for first product tile carousel to show that images are swipable for customer
        if (!layout.isDesktop() && (index === 0 || index === 1) && (!showAutoSwipeProductTileOnPLP || showAutoSwipeProductTileOnPLP === 'false')) {
            var $slider = $(options.productTileCarousel).eq(index);
            var $slickTrack = $slider.find('.slick-track').eq(0);

            var imgSize = $slickTrack.find('img').eq(0).css('width');
            var halfSize = imgSize.replace('px', '') / 2;
            var transformSlickNextStyle = 'translate3d(' + '-' + halfSize + 'px, 0px, 0px)';
            var transformSlickPrevStyle = 'translate3d(0px, 0px, 0px)';

            var slickPrevTimeout = null;
            var slickNextTimeout = setTimeout(function() {
                var swipeLeft = true;
                $slickTrack.on('transitionend', function() {
                    if (swipeLeft) {
                        swipeLeft = false;

                        slickPrevTimeout = setTimeout(function() {
                            $slickTrack.css({
                                'transition': 'transform 1000ms',
                                'transform': transformSlickPrevStyle
                            });
                        }, 500);
                    } else {
                        $slickTrack.css({
                            'transition': '',
                        });
                    }

                    sessionStorage.setItem('showAutoSwipeProductTileOnPLP', true);
                });

                $slickTrack.css({
                    'transition': 'transform 1000ms',
                    'transform': transformSlickNextStyle
                });
            }, 1000);

            $slider.on('swipe', function() {
                clearTimeout(slickNextTimeout);
                if (slickPrevTimeout) {
                    clearTimeout(slickPrevTimeout);
                }

                sessionStorage.setItem('showAutoSwipeProductTileOnPLP', true);
            });
        }
    });
}

module.exports = {
    init: function(params) {
        if (initialized) return;

        initializeParams(params);
        initializeCache();
        initializeDOM();
        initializeEvents();
    },
    updateMainImage: updateMainImage,
    updateTile: updateTile,
    updateTiles: updateTiles,
    updateTileActiveTab: updateTileActiveTab,
    updateSizeGrid: updateSizeGrid,
    setSize: setSize,
    getSize: getSize,
    initProductTileCarousels: initProductTileCarousels,
    updateThumbnailsSlider: updateThumbnailsSlider,
    switchBraSizeGrid: switchBraSizeGrid
}
